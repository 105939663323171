import { T__Category } from "../../../redux/dataSlice";

export interface I__Spot {
  category: T__Category;
  id: number;
  title: string;
}

const Data: Array<I__Spot> = [
  {
    category: "Grocery Stores",
    id: 1,
    title: "Nesters Market",
  },
  {
    category: "Grocery Stores",
    id: 2,
    title: "Nature’s Fare Markets – West Kelowna",
  },
  {
    category: "Grocery Stores",
    id: 3,
    title: "Walmart Supercentre",
  },
  {
    category: "Grocery Stores",
    id: 4,
    title: "Real Canadian Superstore",
  },
  {
    category: "Grocery Stores",
    id: 5,
    title: "Mediterranean Market",
  },
  {
    category: "Pubs & Restaurants",
    id: 6,
    title: "The Modest Butcher Kitchen + Market",
  },
  {
    category: "Pubs & Restaurants",
    id: 7,
    title: "Blu Saffron Bistro",
  },
  {
    category: "Pubs & Restaurants",
    id: 8,
    title: "Old Vines Restaurant",
  },
  {
    category: "Pubs & Restaurants",
    id: 9,
    title: "West Sakura Teriyaki Restaurant",
  },
  {
    category: "Pubs & Restaurants",
    id: 10,
    title: "19 Okanagan Grill + Bar",
  },
  {
    category: "Pubs & Restaurants",
    id: 11,
    title: "Earls Kitchen + Bar",
  },
  {
    category: "Pubs & Restaurants",
    id: 12,
    title: "West Coast Grill & Oyster Bar Kelowna",
  },
  {
    category: "Pubs & Restaurants",
    id: 13,
    title: "Krafty Kitchen + Bar",
  },
  {
    category: "Pubs & Restaurants",
    id: 14,
    title: "Terrace Restaurant",
  },
  {
    category: "Pubs & Restaurants",
    id: 15,
    title: "II Mercato Social Kitchen",
  },
  {
    category: "Pubs & Restaurants",
    id: 16,
    title: "Lakesider Brewing",
  },
  {
    category: "Pubs & Restaurants",
    id: 17,
    title: "The Hatching Post Brewery",
  },
  {
    category: "Pubs & Restaurants",
    id: 18,
    title: "Friends Pub",
  },
  {
    category: "Pubs & Restaurants",
    id: 19,
    title: "Neighbours Pub",
  },
  {
    category: "Pubs & Restaurants",
    id: 20,
    title: "Kelly O’Bryan’s Restaurant and Pub",
  },
  {
    category: "Pubs & Restaurants",
    id: 21,
    title: "O’Flannigan’s Pub",
  },
  {
    category: "Wineries",
    id: 22,
    title: "Mt. Boucherie Estate Winery",
  },
  {
    category: "Wineries",
    id: 23,
    title: "Volcanic Hills Estate Winery",
  },
  {
    category: "Wineries",
    id: 24,
    title: "Beaumount Family Estate Winery",
  },
  {
    category: "Wineries",
    id: 25,
    title: "Little Straw Winery",
  },
  {
    category: "Wineries",
    id: 26,
    title: "Grizzli Winery",
  },
  {
    category: "Wineries",
    id: 27,
    title: "The Hatch",
  },
  {
    category: "Wineries",
    id: 28,
    title: "Indigenous World Winery",
  },
  {
    category: "Wineries",
    id: 29,
    title: "Quails’ Gate Winery",
  },
  {
    category: "Wineries",
    id: 30,
    title: "Mission Hill Family Estate Winery",
  },
  {
    category: "Wineries",
    id: 31,
    title: "Frind Estate Winery",
  },
  {
    category: "Wineries",
    id: 32,
    title: "Crown & Thieves Winery",
  },
  {
    category: "Wineries",
    id: 33,
    title: "Black Swift Vineyards",
  },
  {
    category: "Wineries",
    id: 34,
    title: "The Gallery Winery",
  },
  {
    category: "Parks & Recreation",
    id: 35,
    title: "Lakeview Heights Community Centre",
  },
  {
    category: "Parks & Recreation",
    id: 36,
    title: "Eain Lamont Park",
  },
  {
    category: "Parks & Recreation",
    id: 37,
    title: "Kalamoir Regional Park",
  },
  {
    category: "Parks & Recreation",
    id: 38,
    title: "Casa Rio Waterfront Park",
  },
  {
    category: "Parks & Recreation",
    id: 39,
    title: "Osprey Park",
  },
  {
    category: "Parks & Recreation",
    id: 40,
    title: "Rose Valley Regional Park",
  },
  {
    category: "Parks & Recreation",
    id: 41,
    title: "Gellatly Regional Park",
  },
  {
    category: "Parks & Recreation",
    id: 42,
    title: "Mount Boucherie",
  },
  {
    category: "Parks & Recreation",
    id: 43,
    title: "Two Eagles Golf Course",
  },
  {
    category: "Parks & Recreation",
    id: 44,
    title: "Shannon Lake Golf Course",
  },
  {
    category: "Parks & Recreation",
    id: 45,
    title: "West Kelowna Soccer Dome",
  },
  {
    category: "Parks & Recreation",
    id: 46,
    title: "Pins & Pints Bowling",
  },
];

export default Data;
