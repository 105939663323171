import { combineReducers } from "@reduxjs/toolkit";
import data from "./dataSlice";

const createRootReducer = () => {
  return combineReducers({
    data,
  });
};

export default createRootReducer;
