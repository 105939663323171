import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import LIST from "../components/Home/AmenityMap/Data";
import _ from "lodash";

export type T__Category =
  | "Grocery Stores"
  | "Pubs & Restaurants"
  | "Wineries"
  | "Parks & Recreation";

export interface I__InitialState {
  category: T__Category;
  spot: number;
  lightBox: boolean;
  gallery: {
    index: number;
  };
}

const initialState: I__InitialState = {
  category: "Grocery Stores",
  spot: 1,
  lightBox: false,
  gallery: {
    index: 0,
  },
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateCategory: {
      reducer: (state, action: PayloadAction<{ category: T__Category }>) => {
        const { category } = action.payload;

        const newList = _.filter(LIST, { category });
        const firstID = newList[0].id;

        state.spot = firstID;
        state.category = category;
      },
      prepare: ({ category }: { category: T__Category }) => ({
        payload: { category },
      }),
    },

    updateSpot: {
      reducer: (state, action: PayloadAction<{ spot: number }>) => {
        const { spot } = action.payload;
        const selectSpot = _.find(LIST, { id: spot });
        state.spot = spot;
        if (selectSpot !== undefined) {
          state.category = selectSpot.category;
        }
      },
      prepare: ({ spot }: { spot: number }) => ({
        payload: { spot },
      }),
    },

    openLightBox: {
      reducer: (state) => {
        state.lightBox = true;
      },
      // @ts-ignore
      prepare: () => ({}),
    },

    closeLightBox: {
      reducer: (state) => {
        state.lightBox = false;
      },
      // @ts-ignore
      prepare: () => ({}),
    },

    updateGalleryIndex: {
      reducer: (state, action: PayloadAction<{ index: number }>) => {
        const { index } = action.payload;
        state.gallery.index = index;
      },
      prepare: ({ index }: { index: number }) => ({
        payload: { index },
      }),
    },
  },
});

export const {
  updateCategory,
  updateSpot,
  openLightBox,
  closeLightBox,
  updateGalleryIndex,
} = dataSlice.actions;

// @ts-ignore
export const selectCategory = (state: RootState) => state.data.category;

// @ts-ignore
export const selectSpot = (state: RootState) => state.data.spot;

// @ts-ignore
export const selectLightBox = (state: RootState) => state.data.lightBox;

// @ts-ignore
export const selectGalleryIndex = (state: RootState) =>
  state.data.gallery.index;

export default dataSlice.reducer;
